@import '~react-perfect-scrollbar/dist/css/styles.css';
@import "~react-datepicker/dist/react-datepicker";
@import "~pnotify/dist/PNotifyBrightTheme.css";
@import "~react-datetime/css/react-datetime";
@import "~react-dual-listbox/src/scss/react-dual-listbox";
@import "~nvd3/build/nv.d3.css";

@import "ngx-perfect-scrollbar";
@import "react-animated-modal";
@import "react-toast-notifications";
@import "css-animator";
@import "pnotify";
@import "react-tag-automplete";
@import "react-dual-listbox";
@import "peity";
@import "jquery-jvectormap-2.0.2.scss";
@import "react-datepicker.scss";

@import "react-bootstrap/react-bootstrap";

.dropzone {
  .dz-progress {
    display: none;
  }
}