/* ================================    Custom dark style Datepicker (react-datepicker) Start  ===================== */

.date-picker {
    .react-datepicker__week {
        background-color: $dark-inputs!important;
        font-family: $theme-font-family!important;
    }
    .react-datepicker__day {
        color: #b5bbc0!important;
        font-family: $theme-font-family!important;
    }
}

.react-datepicker__triangle {
    background-color: $dark-inputs!important;
    color: $dark-inputs!important;
    border-color: rgb(255, 0, 0)!important;
  }

.react-datepicker__month-container {
    background-color: $dark-inputs!important;
    color: #b5bbc0!important;
    border-color: tint-color(rgba(13, 109, 253, 0.50))!important;
    outline: 0!important;
    box-shadow: 0 0 0 0.07rem rgba(13, 109, 253, 1)!important;
    border-radius: 4px!important;
}
 
.react-datepicker__header {
    background-color: $dark-inputs!important;
    color: #b5bbc0!important;
    font-family: $theme-font-family!important;
}

.react-datepicker__current-month {
    color: #b5bbc0!important;
}

.react-datepicker__day-name {
    color: #b5bbc0!important;
}

.react-datepicker__navigation--previous {
    top: 9px!important;
}

.react-datepicker__navigation--next {
    top: 9px!important;
}

/* ================================    Custom dark style Datepicker (react-datepicker) End  ===================== */