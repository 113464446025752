.react-tags {
  position: relative;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;
  border-radius: 4px;
  background-color: $dark-selects!important;
  border-color: #1A1A1C!important;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: tint-color(rgba(13, 109, 253, 0.50))!important;
  outline: 0;
  box-shadow: 0 0 0 0.07rem rgba(13, 109, 253, 1)!important;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 5px 5px 0px 0;
  padding: 5px 10px;
  border-radius: 3px;
  background-color: $secondary-color!important;
  border: 0;
  /* match the font styles */
  font-size: 16px!important;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  margin-left: 8px;
}


.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  input{
    background: transparent;
    border:0;
    color:#b5bbc0;
  }
}

@media screen and (min-width: 30em) {

  .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
  }
    
}
    
.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: 16px!important;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

//Component TagCommunityIdentifiers

#community-identifiers .react-tags .react-tags__search input
{
  color:white!important;
}