.dashboard-date-uploaded
{
	text-align: right;
    font-size: 12px;
    margin-bottom: 5px;
    font-style: oblique;
}

.dashboard-card
{
	border-radius: 4px;
	background-color:rgba(0,0,0,0.5);
	padding:20px 30px;
	height:auto!important;
	width:100%;
}

.dashboard-card h1
{
	color:white!important;
	font-weight:bolder;
	font-size:25px;
}

.dashboard-card h2
{
	color:white!important;
	font-weight:bolder;
	font-size:20px;
	text-transform:uppercase;
}

.dashboard-card-list-number
{
	padding:5px;
	//background-color:rgba(242,7,117,0.3);
	background-color: rgba(255,165,0,0.4);
	border-radius:4px;
	border: 1px solid rgba(255,165,0,1);
	//border: 1px solid rgba(242,7,117,1);
	width:40px;
	text-align:center;
	font-weight: bold;
}

.dashboard-card-list-title
{
	font-size:14px;
	color:white;
	font-weight:bolder;
}

.dashboard-card p
{
	font-size:15px;
}

.dashboard-card span
{
	font-weight:bolder;
	font-size:20px;
	padding-top:0px;
	padding-left:10px;
	color:rgba(242,7,117,1)!important;
}

.dashboard-card span.dashboard-card-news
{
	background-color:#04d9d9;
	padding:2px 6px;
	margin-left:10px;
	font-weight:bolder;
	font-style:oblique;
	color:black!important;	
	text-transform:uppercase;
	border-radius:4px;
	font-size:12px;
}